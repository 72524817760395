import { ref } from 'vue'

const searchBar = ref<string>('')
const bradCrumbs = ref<string[]>([])
const showInputSearch = ref<boolean>(false)
const showFilterByDate = ref<boolean>(false)
const showBtnMetaAll = ref<boolean>(false)
const showReturn = ref<boolean>(true)
const showBradCrumbs = ref<boolean>(true)
const showFilterGrades = ref<boolean>(true)
const showColaborators = ref<boolean>(true)
const showCadastrarMetas = ref<boolean>(true);
const iconShowValues = ref<boolean>(true);
const exportGoal = ref<boolean>(true);

const dateMeta = ref<Date>(new Date());
const filterRole = ref<number>(0);

const filterMeta = ref<any>();
const filterNota = ref<any>();

const hasClient = ref<boolean>(true);
const hasResale = ref<boolean>(true);

const showTutorial = ref<boolean>(false);
const isTutorial = ref<boolean>(true);
const isFilterTutorial = ref<boolean>(true);

const carousselLoaded = ref<boolean>(true);

export {
    showInputSearch,
    searchBar,
    showBtnMetaAll,
    showFilterByDate,
    showBradCrumbs,
    showReturn,
    bradCrumbs,
    showFilterGrades,
    showColaborators,
    showCadastrarMetas,
    filterRole,
    filterMeta,
    filterNota,
    hasClient,
    hasResale,
    dateMeta,
    iconShowValues,
    exportGoal,
    showTutorial,
    isTutorial,
    isFilterTutorial,
    carousselLoaded
}