import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Goal, GoalInsert } from '../model'
import { format } from 'date-fns';

const controller = 'goals'

export async function getPaginate(page: number, size: number, name?: string, start_date?: Date, end_date?: Date, role_id?: number, goalClosed?: boolean, is_export?: boolean): Promise<any> {
    const url = `${controller}/?page=${page}&page_length=${size}${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}${name ? `&goal_name=${name}` : ''}${role_id ? `&roles_id=${role_id}` : ''}${goalClosed ? `&goal_closed=${goalClosed}` : ''}${is_export ? `&is_export=${is_export}` : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}


export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}


export async function getUserPaginate(userId: number, page: number, size: number, name?: string, start_date?: Date, end_date?: Date, role_id?: number, goalClosed?: boolean, is_export?: boolean): Promise<any> {
    const url = `${controller}/user/${userId}?page=${page}&page_length=${size}${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}${name ? `&goal_name=${name}` : ''}${role_id ? `&roles_id=${role_id}` : ''}${goalClosed ? `&goal_closed=${goalClosed}` : ''}${is_export ? `&is_export=${is_export}` : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}

export async function create(payload: GoalInsert): Promise<Goal> {

    const { data } = await http.post<any, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(id: number, payload: Goal): Promise<Goal> {

    const { data } = await http.put<Goal, AxiosResponse>(`${controller}/${id}`, payload);
    return data;
}

export async function updateBrands(id: number, payload: number[]): Promise<Goal>
{
    const { data } = await http.put<Goal, AxiosResponse>(`${controller}/brands_goals/${id}`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {

    const { status } = await http.delete(`${controller}/${id}`);

    return status === 204;
}